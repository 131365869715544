import React, { useEffect, useState } from "react";
import { Col, Container, ProgressBar, Row, Spinner } from "react-bootstrap";
import styled from "styled-components";
import { Color } from "../../theme/Color";
import { Font } from "../../theme/Font";
import Loader from "../../utils/Loader";
import { scroll } from "../../utils/scrollTop";
import { isValidEmail } from "../../utils/validation";
import ReportImg from "../../assets/small assests/data_reports.webp";
import { BigYellowLinkButton, Description, Section, Section112, SubTitle, SubmitButton, TitleSection } from "../DesignComponent/DesignComponents";
import { metaData } from "../../utils/metaData";
import Footer from "../CommonComponent/Footer";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import axios from "axios";
import Question from "./Question";
// import Step from "./Step";

const Quiz = () => {
    useEffect(() => {
        metaData("50506");
        scroll();
    }, []);
    const [value, setValue] = useState(15);
    const [questions, setQuestions] = useState([]);

    const [Loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);

    const [formData, setformData] = useState({
        step1: {
            question_1: '',
            question_2: '',
            question_3: 15,
            question_4: '',
            question_5: ''
        },
        step2: {
            question_1: '',
            question_2: '',
            question_3: 15,
            question_4: '',
            question_5: ''
        },
        step3: {
            question_1: '',
            question_2: '',
            question_3: 15,
            question_4: '',
            question_5: ''
        },
        step4: {
            question_1: '',
            question_2: '',
            question_3: 15,
            question_4: '',
            question_5: ''
        },
        step5: {
            fullName: '',
            email: '',
            phone: '',
            companyName: ''
        }
    });

    const [results, setResults] = useState({
        step1: { percentage: 0, totalMarks: 0 },
        step2: { percentage: 0, totalMarks: 0 },
        step3: { percentage: 0, totalMarks: 0 },
        step4: { percentage: 0, totalMarks: 0 },
        step5: { percentage: 0, totalMarks: 0 }
    });

    const [errors, setErrors] = useState({
        step1: {},
        step2: {},
        step3: {},
        step4: {},
        step5: {}
    });

    const handleInputTest = (e) => {
        const { name, value } = e.target;

        setformData((prevData) => ({
            ...prevData,
            [`step${step}`]: {
                ...prevData[`step${step}`],
                [name]: value
            }
        }));
    };

    const handleSliderChangeTest = (value) => {
        setformData((prevData) => ({
            ...prevData,
            [`step${step}`]: {
                ...prevData[`step${step}`],
                question_3: String(value)
            }
        }));
    };

    const validateForm = () => {
        const newErrors = {
            question_1: '',
            question_2: '',
            question_4: '',
            question_5: '',
            fullName: '',
            email: '',
            phone: '',
            companyName: ''
        };

        let isValid = true;

        const currentForm = formData[`step${step}`];

        if (step === 5) {
            if (!currentForm.fullName.trim()) {
                newErrors.fullName = "Please fill the answer.";
                isValid = false;
            }
            if (!currentForm.email.trim()) {
                newErrors.email = "Please fill the answer.";
                isValid = false;
            }
            if (!currentForm.phone.trim()) {
                newErrors.phone = "Please fill the answer.";
                isValid = false;
            }
            if (!currentForm.companyName.trim()) {
                newErrors.companyName = "Please fill the answer.";
                isValid = false;
            }
        } else {
            if (!currentForm.question_1) {
                newErrors.question_1 = 'Question 1 is required';
                isValid = false;
            }
            if (!currentForm.question_2) {
                newErrors.question_2 = 'Question 2 is required';
                isValid = false;
            }
            if (!currentForm.question_4) {
                newErrors.question_4 = 'Question 4 is required';
                isValid = false;
            }
            if (!currentForm.question_5) {
                newErrors.question_5 = 'Question 5 is required';
                isValid = false;
            }
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            [`step${step}`]: newErrors
        }));

        return isValid;
    };

    const nextStep2 = (e) => {
        e.preventDefault();
        const currentForm = formData[`step${step}`];
        if (!validateForm()) {
            return;
        }

        let totalMarks = 0;
        if (step < 5) {
            Object.keys(currentForm).forEach((question) => {
                const userAnswer = currentForm[question];
                const correctAnswer = questions[step - 1].question_group[question]?.answer;

                const isCorrect = userAnswer === correctAnswer;
                const marks = isCorrect ? 5 : 0;
                totalMarks += marks;
                const percent = totalMarks / 25 * 100

                setResults((prevResults) => ({
                    ...prevResults,
                    // [`step${step}`]: totalMarks
                    [`step${step}`]: {
                        ...prevResults[`step${step}`],
                        totalMarks: totalMarks,
                        percentage: percent
                    }
                }));

            });
        }

        setStep((prevStep) => prevStep + 1);
    };

    const fetchData = async () => {
        const URL =
            "https://staging.theleansuite.com/backend/wp-json/wp/v2/pages/50506?_fields=acf&acf_format=standard";
        const response = await axios.get(URL);
        setQuestions(response.data.acf.quiz_questions.quadrant)
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
    }, [questions])

    const emailRegex =
        /^[a-zA-Z0-9._%+-]+@(?!gmail\.com|yahoo\.com|hotmail\.com)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;

    const prevStep = () => {
        setStep(step - 1);
    };
    // const payload = {
    //     Email,
    //     fullName,
    //     Company_Name,
    //     MyRole,
    //     TeamSize,
    //     CompanySize,
    //     Phone,
    // };

    // const handleSubmit = async (e) => {
    //     if (!fullName.trim()) {
    //         setFullNameError("Please fill the answer.");
    //         return;
    //     }
    //     if (!Email.trim()) {
    //         setEmailError("Please fill the answer.");
    //         return;
    //     }
    //     if (!Phone.trim()) {
    //         setPhoneError("Please fill the answer.");
    //         return;
    //     }
    //     if (!Company_Name.trim()) {
    //         setCompanyNameError("Please fill the answer.");
    //         return;
    //     }

    //     e.preventDefault();

    //     // console.log("clicked...", payload);
    //     setLoading(true);
    //     try {
    //         setStep(step + 1);
    //         // showToastMessage();
    //         setLoading(false);
    //     } catch (error) {
    //         setLoading(false);
    //         // shownToastMessage();
    //     }
    // };
    useEffect(() => {
        scroll();
    }, []);
    const required = <span className="text-danger">*</span>;

    const progress = (step / 5) * 100;

    return (
        <>
            <Section112
                className="quiz-sec d-flex align-items-center"
                style={{ backgroundColor: Color.theme_four }}
            >
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-6">
                            {step === 5 && (
                                <img src={ReportImg} alt={"ReportImg"} className="w-100" />
                            )}
                            {step !== 5 && (
                                <SubTitle className="sub-title mb-2 text-center">Quiz</SubTitle>
                            )}
                            <TitleSection className="mb-3 text-center">
                                Lorem ipsum dolor sit amet
                            </TitleSection>
                            <Description
                                className="mb-4 text-center"
                                style={{ color: Color.lighterGrey }}
                            >
                                Lorem ipsum dolor sit amet consectetur. Et duis placerat risus magna in leo. Laoreet ultricies odio interdum commodo. Felis massa risus tellus egestas felis et mauris ut. Sit rhoncus turpis nulla arcu facilisi aliquet dolor proin.
                            </Description>
                            <hr className="w-75 mx-auto" />
                            {step !== 5 && step !== 6 && (
                                <ProgressBar now={progress} className="mb-4" />
                            )}
                            {step === 1 && (
                                <div className="step-1">
                                    <div>

                                        {questions.length > 0 && questions[0]?.question_group && Object.entries(questions[0].question_group).map(([key, value]) => (
                                            <Question
                                                key={key}
                                                questionData={{ ...value, id: key }}
                                                formData={formData.step1}
                                                handleInputTest={handleInputTest}
                                                handleSliderChangeTest={handleSliderChangeTest}
                                                errors={errors.step1}
                                            />
                                        ))}
                                        <SubmitButton
                                            onClick={nextStep2}
                                            type="submit"
                                            className="btn w-100 mb-4"
                                        >
                                            {Loading ? "Loading..." : "Continue"}
                                        </SubmitButton>
                                    </div>
                                </div>
                            )}
                            {step === 2 && (
                                <div className="step-2">
                                    <div>
                                        {questions.length > 0 && questions[1]?.question_group && Object.entries(questions[1].question_group).map(([key, value]) => (
                                            <Question
                                                key={key}
                                                questionData={{ ...value, id: key }}
                                                formData={formData.step2}
                                                handleInputTest={handleInputTest}
                                                handleSliderChangeTest={handleSliderChangeTest}
                                                errors={errors.step1}
                                            />
                                        ))}
                                        <div className="d-flex gap-3 ">
                                            <BackButton
                                                type="Back"
                                                onClick={prevStep}
                                                className="btn border w-100 mb-4"
                                            >
                                                {Loading ? "Loading..." : "Back"}
                                            </BackButton>
                                            <SubmitButton
                                                onClick={nextStep2}
                                                type="submit"
                                                className="btn w-100 mb-4"
                                            >
                                                {Loading ? "Loading..." : "Continue"}
                                            </SubmitButton>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {step === 3 && (
                                <div className="step-3">
                                    <div>

                                        {questions.length > 0 && questions[2]?.question_group && Object.entries(questions[2].question_group).map(([key, value]) => (
                                            <Question
                                                key={key}
                                                questionData={{ ...value, id: key }}
                                                formData={formData.step3}
                                                handleInputTest={handleInputTest}
                                                handleSliderChangeTest={handleSliderChangeTest}
                                                errors={errors.step1}
                                            />
                                        ))}
                                        <div className="d-flex gap-3 ">
                                            <BackButton
                                                type="Back"
                                                onClick={prevStep}
                                                className="btn border w-100 mb-4"
                                            >
                                                {Loading ? "Loading..." : "Back"}
                                            </BackButton>
                                            <SubmitButton
                                                onClick={nextStep2}
                                                type="submit"
                                                className="btn w-100 mb-4"
                                            >
                                                {Loading ? "Loading..." : "Continue"}
                                            </SubmitButton>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {step === 4 && (
                                <div className="step-3">
                                    <div>

                                        {questions.length > 0 && questions[3]?.question_group && Object.entries(questions[3].question_group).map(([key, value]) => (
                                            <Question
                                                key={key}
                                                questionData={{ ...value, id: key }}
                                                formData={formData.step4}
                                                handleInputTest={handleInputTest}
                                                handleSliderChangeTest={handleSliderChangeTest}
                                                errors={errors.step1}
                                            />
                                        ))}
                                        <div className="d-flex gap-3 ">
                                            <BackButton
                                                type="Back"
                                                onClick={prevStep}
                                                className="btn border w-100 mb-4"
                                            >
                                                {Loading ? "Loading..." : "Back"}
                                            </BackButton>
                                            <SubmitButton
                                                onClick={nextStep2}
                                                type="submit"
                                                className="btn w-100 mb-4"
                                            >
                                                {Loading ? "Loading..." : "Continue"}
                                            </SubmitButton>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {step === 5 && (
                                <div className="thank-you-msg">
                                    <div>
                                        <div className="mb-3">
                                            <Label className="form-label">
                                                Name:
                                            </Label>
                                            <Input
                                                type="text"
                                                name="fullName"
                                                value={formData.step5.fullName}
                                                onChange={handleInputTest}
                                                className="form-control p-2"
                                            />{" "}
                                            {/* {FullNameError && <Error> {FullNameError}</Error>} */}
                                            {errors.step5?.fullName && <Error>{errors.step5.fullName}</Error>}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">
                                                Email ID:
                                            </Label>
                                            <Input
                                                type="email"
                                                name="email"
                                                className="form-control p-2"
                                                value={formData.step5.email}
                                                onChange={handleInputTest}
                                            />{" "}
                                            {/* {emailError && <Error> {emailError}</Error>} */}
                                            {errors.step5?.email && <Error>{errors.step5.email}</Error>}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">
                                                Phone:
                                            </Label>
                                            <PhoneInput
                                                country="us"
                                                value={formData.step5.phone}
                                                onChange={(phone) =>
                                                    handleInputTest({ target: { name: "phone", value: phone } })
                                                }
                                                inputStyle={{
                                                    width: "100%",
                                                    paddingTop: "10px",
                                                    paddingBottom: "10px",
                                                    borderRadius: "2px",
                                                    height: "unset",
                                                }}
                                                dropdownStyle={{
                                                    color: "black",
                                                }}
                                            />
                                            {/* {phoneError && <Error>{phoneError}</Error>} */}
                                            {errors.step5?.phone && <Error>{errors.step5.phone}</Error>}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">
                                                Company Name:
                                            </Label>
                                            <Input
                                                type="text"
                                                name="companyName"
                                                value={formData.step5.companyName}
                                                onChange={handleInputTest}
                                                className="form-control p-2"
                                            />{" "}
                                            {/* {companyNameError && <Error> {companyNameError}</Error>} */}
                                            {errors.step5?.companyName && <Error>{errors.step5.companyName}</Error>}
                                        </div>
                                        <div className="d-flex gap-3 ">
                                            <SubmitButton
                                                onClick={nextStep2}
                                                type="submit"
                                                className="btn w-100 mb-4"
                                            >
                                                {Loading ? "Loading..." : "Submit"}
                                            </SubmitButton>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {step === 6 && (
                                <div className="report-sec">
                                    <div className="d-flex gap-lg-5 gap-3">
                                        <div className="progress-item d-flex justify-content-center flex-wrap">
                                            <CircularProgressbar
                                                value={results.step1.percentage}
                                                text={results.step1.percentage}
                                                background
                                                styles={buildStyles({
                                                    pathColor: "#FFC000",
                                                    textColor: "#FFC000",
                                                    backgroundColor: "#FFC0001F",
                                                    trailColor: "transparent",
                                                })}
                                            />
                                            <Label className="text-center">Area - 1</Label>
                                        </div>
                                        <div className="progress-item d-flex justify-content-center flex-wrap">
                                            <CircularProgressbar
                                                value={results.step2.percentage}
                                                text={results.step2.percentage}
                                                background
                                                styles={buildStyles({
                                                    pathColor: "#4ABA00",
                                                    textColor: "#4ABA00",
                                                    backgroundColor: "#4ABA001F",
                                                    trailColor: "transparent",
                                                })}
                                            />
                                            <Label className="text-center">Area - 2</Label>
                                        </div>
                                        <div className="progress-item d-flex justify-content-center flex-wrap">
                                            <CircularProgressbar
                                                value={results.step3.percentage}
                                                text={results.step3.percentage}
                                                background
                                                styles={buildStyles({
                                                    pathColor: "#FF3D00",
                                                    textColor: "#FF3D00",
                                                    backgroundColor: "#FF3D001F",
                                                    trailColor: "transparent",
                                                })}
                                            />
                                            <Label className="text-center">Area - 3</Label>
                                        </div>
                                        <div className="progress-item d-flex justify-content-center flex-wrap">
                                            <CircularProgressbar
                                                value={results.step4.percentage}
                                                text={results.step4.percentage}
                                                background
                                                styles={buildStyles({
                                                    pathColor: "#FFC000",
                                                    textColor: "#FFC000",
                                                    backgroundColor: "#FFC0001F",
                                                    trailColor: "transparent",
                                                })}
                                            />
                                            <Label className="text-center">Area - 4</Label>
                                        </div>
                                    </div>
                                    <hr className="w-75 mx-auto" />
                                    <div className="w-50 mx-auto py-4">
                                        <CircularProgressbarWithChildren
                                            value={100}
                                            background
                                            styles={buildStyles({
                                                pathColor: "transparent",
                                                trailColor: "transparent",
                                                backgroundColor: "#FFC0001F",
                                            })}
                                        >
                                            <CircularProgressbarWithChildren
                                                value={((results.step1.percentage * 25) / 100) + 75}
                                                circleRatio={1}
                                                styles={buildStyles({
                                                    pathColor: "#FFC000",
                                                    trailColor: "transparent",
                                                })}
                                            >
                                                <CircularProgressbarWithChildren
                                                    value={((results.step2.percentage * 34) / 100) + 66}
                                                    circleRatio={0.75}
                                                    styles={buildStyles({
                                                        pathColor: "#FF3D00",
                                                        trailColor: "transparent",
                                                    })}
                                                >
                                                    <CircularProgressbarWithChildren
                                                        value={((results.step3.percentage * 50) / 100) + 50}
                                                        circleRatio={0.5}
                                                        styles={buildStyles({
                                                            pathColor: "#4ABA00",
                                                            trailColor: "transparent",
                                                            // zIndex: 222,
                                                        })}
                                                    >
                                                        <CircularProgressbar
                                                            value={results.step4.percentage}
                                                            text={`${Math.round(((results.step1.percentage + results.step2.percentage + results.step3.percentage + results.step4.percentage) / 400) * 100)}%`}
                                                            circleRatio={0.25}
                                                            background
                                                            styles={buildStyles({
                                                                pathColor: "#FFC000",
                                                                textColor: "#FFC000",
                                                                backgroundColor: "#FFC0001F",
                                                                trailColor: "transparent",
                                                            })}
                                                        />
                                                    </CircularProgressbarWithChildren>
                                                </CircularProgressbarWithChildren>
                                            </CircularProgressbarWithChildren>
                                        </CircularProgressbarWithChildren>
                                        <TitleSection className="my-4 text-center">
                                            Overall Score
                                        </TitleSection>
                                        <BigYellowLinkButton to="/schedule-demo" className="w-100" style={{ minWidth: "unset" }}>CTA</BigYellowLinkButton>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Section112>
            <Footer />
        </>
    );
};
export default Quiz;
const Tit = styled.label`
                text-align: start;
                justify-content: start;
                color: ${Color.white};
                font-family: ${Font.basel};
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 21px;
                margin-bottom: 8px;
                text-align: start;
                `;
const BackButton = styled.button`
                font-size: 18px;
                border-radius: 4px;
                padding: 10px;
                font-family: ${Font.basel};
                font-weight: bold;
                &:hover {
                    // background-color: ${Color.white} !important;
                    // color: ${Color.theme_first} !important;
                }
                @media (max-width: 767px) {
                    font - size: 16px;
  }
                `;
const SmallText = styled.p`
                font-size: 14px;
                font-family: ${Font.basel};
                `;
const Label = styled.label`
                font-size: 16px;
                font-family: ${Font.basel};
                font-weight:bold;
                `;
const Input = styled.input`
                border-radius: 4px;
                `;
const Select = styled.select`
                border-radius: 4px;
                `;
const Error = styled.p`
                color: red;
                font-size: 14px;
                // padding: 10px 2px 0 0;
                margin: 0 !important;
                `;
const RadioButton = styled.label`
                background-color: ${Color.white};
                border: 1px solid #00000033;
                color: #6B7280;
                width:100%;
                padding:8px;
                &:not(:last-child){
                    margin - bottom:8px;
}
                .btn-check:checked+&{
                    background - color: ${Color.yelow};
                border: 1px solid ${Color.yelow};
                color: ${Color.text};
}
                .btn-check+&:hover{
                    background - color: ${Color.yelow};
                border: 1px solid ${Color.yelow};
                color: ${Color.text};
}
                `;
