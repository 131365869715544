import styled from "styled-components";
import { Font } from "../../theme/Font";
import { Color } from "../../theme/Color";
import Slider from "rc-slider";


const Question = ({ questionData, formData, handleInputTest, errors, handleSliderChangeTest }) => {
    return (
        <div className="mb-3">
            {questionData.dropdown_options && (
                <>
                    <Label className="form-label">{questionData.question}</Label>
                    <select
                        name={questionData.id}
                        className="form-select p-2"
                        value={formData[questionData.id]}
                        onChange={handleInputTest}
                    >
                        <option value="" disabled selected>
                            Dropdown
                        </option>
                        {questionData.dropdown_options.options.map((opt) => (
                            <option key={opt.option} value={opt.option}>
                                {opt.option}
                            </option>
                        ))}
                    </select>
                    {errors[questionData.id] && <Error>{errors[questionData.id]}</Error>}
                </>
            )}

            {questionData.select_options && (
                <>
                    <Label className="form-label">{questionData.question}</Label>
                    <div className="">
                        {questionData.select_options.options.map((opt) => (
                            <div key={opt.option}>
                                <input
                                    type="radio"
                                    className="btn-check"
                                    name={questionData.id}
                                    value={opt.option}
                                    onChange={handleInputTest}
                                    id={opt.option}
                                    autoComplete="off"
                                />
                                <RadioButton className="btn mb-2" htmlFor={opt.option}>
                                    {opt.option}
                                </RadioButton>
                            </div>
                        ))}
                    </div>
                    {errors[questionData.id] && <Error>{errors[questionData.id]}</Error>}
                </>
            )}

            {questionData.range_slider == 'Slider' && (
                <>
                    <Label className="form-label">{questionData.question}</Label>
                    <div className="p-4 bg-white">
                        <div className="text-center my-2">{15}</div>
                        <Slider
                            min={10}
                            max={40}
                            defaultValue={15}
                            value={formData[questionData.id]}
                            onChange={handleSliderChangeTest}
                            marks={{ 10: '10', 15: '15', 20: '20', 25: '25', 30: '30', 35: '35', 40: '40' }}
                            step={null}
                        />
                    </div>
                </>
            )}

            {!questionData.select_options && !questionData.dropdown_options && questionData.question !== "Question 3" && (
                <>
                    <Label className="form-label">{questionData.question}</Label>
                    <Input
                        type="text"
                        name={questionData.id}
                        value={formData[questionData.id]}
                        onChange={handleInputTest}
                        className="form-control p-2"
                    />
                    {errors[questionData.id] && <Error>{errors[questionData.id]}</Error>}
                </>
            )}
        </div>
    );
};

export default Question

const Label = styled.label`
  font-size: 16px;
  font-family: ${Font.basel};
  font-weight:bold;
`;
const Input = styled.input`
  border-radius: 4px;
`;
const Error = styled.p`
  color: red;
  font-size: 14px;
  // padding: 10px 2px 0 0;
  margin: 0 !important;
`;
const RadioButton = styled.label`
background-color: ${Color.white};
border: 1px solid #00000033;
color: #6B7280;
width:100%;
padding:8px;
&:not(:last-child){
margin-bottom:8px;
}
.btn-check:checked+&{
background-color: ${Color.yelow};
border: 1px solid ${Color.yelow};
color: ${Color.text};
}
.btn-check+&:hover{
background-color: ${Color.yelow};
border: 1px solid ${Color.yelow};
color: ${Color.text};
}
`;
